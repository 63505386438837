/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import TwitterImage from "../../content/assets/twitter.png"
import FacebookImage from "../../content/assets/facebook.png"
import StravaImage from "../../content/assets/strava.png"
import { rhythm } from "../utils/typography"

function Bio() {
    return (
        <StaticQuery
            query={bioQuery}
            render={data => {
                const { author, social } = data.site.siteMetadata
                return (
                    <div
                        style={{
                            display: `flex`,
                            marginBottom: rhythm(2.5),
                        }}
                    >
                        <Image
                            fixed={data.avatar.childImageSharp.fixed}
                            alt={author}
                            style={{
                                marginRight: rhythm(1 / 2),
                                marginBottom: 0,
                                minWidth: 50,
                                borderRadius: `100%`,
                            }}
                            imgStyle={{
                                borderRadius: `50%`,
                            }}
                        />
                        <p>
                            Written by <strong>{author}</strong>
                            <br />
                            <br />
                            <em>Follow me on:</em>
                            <br />
                            <a
                                href={`https://twitter.com/${social.twitter}`}
                                className="link-no-underscore"
                            >
                                <img
                                    src={TwitterImage}
                                    className="social-logo"
                                    alt="Twitter"
                                />
                            </a>
                            <a
                                href={`https://facebook.com/${social.facebook}`}
                                className="link-no-underscore"
                            >
                                <img
                                    src={FacebookImage}
                                    className="social-logo"
                                    alt="Facebook"
                                />
                            </a>
                            <a
                                href={`https://strava.com/athletes/${social.strava}`}
                                className="link-no-underscore"
                            >
                                <img
                                    src={StravaImage}
                                    className="social-logo"
                                    alt="Strava"
                                />
                            </a>
                        </p>
                    </div>
                )
            }}
        />
    )
}

const bioQuery = graphql`
    query BioQuery {
        avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
            childImageSharp {
                fixed(width: 50, height: 50) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        site {
            siteMetadata {
                author
                social {
                    twitter
                    facebook
                    strava
                }
            }
        }
    }
`

export default Bio
